import * as React from "react"

import Seo from "../components/seo"
import categoriesData from '../../content/products/categories.yaml'
import { Link, useStaticQuery, graphql } from "gatsby"
import "./categories-grid.css"
import limparImageMapper from "../components/limpar-svg-images"

const CategoriesGrid = ({maxItems, linkToAll}) => {
  const data = useStaticQuery(graphql`
  query {
    allStrapiCategory(sort: {order: ASC, fields: order}) {
      nodes {
        title
        slug
        text {
          data {
            text
          }
        }
        image {
          url
          localFile {
            publicURL
            childrenImageSharp {
              fluid {
                base64
              }
            }
          }
        }
      }
    }
  }
`)
return (
  <>
    <div
      style={{
        alignItems: 'center'
      }}
      className="row g-md-5 lp-categories-list my-2">
      {data.allStrapiCategory.nodes.slice(0,maxItems?maxItems:data.allStrapiCategory.nodes.length).map((category, categoryIndex) => {
        return (
          <div key={categoryIndex} className="col-12 col-md-6">
            <Link className="link-dark" to={`/${category.slug}`}>
              <div className="lp-categories-category text-center">
                <img
                  className="img-fluid"
                  src={
                    category.image ?
                    category.image?.localFile.publicURL :
                    limparImageMapper['logo-svg-light-mode']
                  }
                  alt="Limpar Model A9"
                />
                {
                  category.title &&
                  <h3 className="lp-sup-cat-title">{category.title}</h3>
                }
                {
                  category.text?.data?.text &&
                  <div className="lp-cat-desc">{category.text.data.text}</div>
                }
              </div>
            </Link>
          </div>
        )
      })}
    </div>
    { linkToAll && 
    <div
      style={{
        textAlign: 'center'
      }}
      className="row">
      <div className="col">
        <Link className="px-4 lp-styled-btn btn btn-primary" to={`/${linkToAll}`}>
          Όλες οι κατηγορίες
        </Link>
      </div>
    </div>
    }
  </>
)}

// export const Head = () => <Seo title="Page two" />

export default CategoriesGrid
