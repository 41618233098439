import * as React from "react"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/TextPlugin";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import data from '../../content/pages/index.yaml'
import categoriesData from '../../content/products/categories.yaml'
// import * as styles from "../components/index.module.css"
import './index.css'
import limparImageMapper from '../components/limpar-svg-images'
import { useEffect } from "react";
import CategoriesGrid from "../components/categories-grid";
// import { useInView } from 'react-intersection-observer'
import heroBg from "../images/limpar-section-background-3-q80.webp"
import darkBg from "../images/limpar-section-background-q80.webp"
import LoaderSVG from "../images/limpar-logo-white.svg"

const IndexPage = () => {

  const preloaderSvg = `<svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
          <animateTransform 
            attributeName="transform" 
            attributeType="XML" 
            type="rotate"
            dur="1s" 
            from="0 50 50"
            to="360 50 50" 
            repeatCount="indefinite" />
      </path>
    </svg>`;

  gsap.registerPlugin(TextPlugin)
  gsap.registerPlugin(ScrollTrigger)

  // const heroRef = React.useRef(null);
  const canvasRef = React.useRef()
  const [modelAnimEnd, setModelAnimEnd] = React.useState({ fixed: false, position: null })
  const [transitElements, setTransitElements] = React.useState({ hero: false, features: false })

  // Limpar logo in model background position change
  React.useEffect(() => {
    // const element = heroRef.current

    gsap.fromTo(
      ".lp-limpar-msg",
      // element.querySelector(".lp-limpar-msg"),
      {
        x: 2000,
      },
      {
        x: -5000,
        scrollTrigger: {
          start: "top",
          trigger: ".hero-wrapper",
          end: "bottom 1000px",
          scrub: 0.5,
        },
      }
    )
  }, []);

  // Features section animation (6 features in radial position)
  React.useEffect(() => {
    ScrollTrigger.matchMedia({
      "(min-width: 768px)": function () {
          const containers = gsap.utils.toArray(".lp-features-row");
          const features_tl = gsap.timeline({
            scrollTrigger: {
              trigger: ".lp-features-row",
              scrub: 0.5,
              start: "top -100px",
              end: "bottom",
              stagger: 2,
              pin: true
            }
          });

          features_tl.fromTo('.lp-features-row > .col-md-12 ', {height: 300}, {height: 150});

          containers.forEach(function (container) {
            gsap.utils.toArray(".lp-feature").forEach(function (feature) {
              features_tl.fromTo(
                feature,
                { autoAlpha: 0 },
                {
                  autoAlpha: 1, duration: 3,
                  stagger: 2,
                }
              );
            });
          });
      }
    })
  }, [])

  // Hero model feature messages fade in / out
  React.useEffect(() => {
    var containers = gsap.utils.toArray(".lp-hero-msgs")
    var features_tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#lp-hero",
        scrub: 0.5,
        snap: {
          snapTo: "labelsDirectional",
          duration: { min: 0.2, max: 0.5 },
        },
        start: "top",
        end: "bottom 1000px",
        stagger: 2,
      },
    })

    containers.forEach(function (container) {
      gsap.utils.toArray(".lp-text-primary").forEach(function (feature, index) {
        features_tl.fromTo(feature, { autoAlpha: 0 }, { autoAlpha: 1 })
        features_tl.to(feature, { autoAlpha: 0 })
      })
    })
  }, [])

  // Purifier image sequence animation
  React.useEffect(() => {
    const canvas = document.getElementById("the-filter")
    const context = canvas.getContext("2d")

    canvas.width = 2000
    canvas.height = 1125

    const frameCount = 340
    const currentFrame = index => {
      let final_index = 0
      if (index < 170) {
        final_index = 670 - index
      } else {
        final_index = index - 170 + 500
      }
      return `/model/${final_index
        .toString()
        .padStart(4, "0")}.webp`
    }

    const images = []
    const purifier = {
      frame: 0,
    }

    for (let i = 0; i < frameCount; i++) {
      const img = new Image()
      img.src = currentFrame(i)
      images.push(img)
    }

    gsap.to(purifier, {
      frame: frameCount - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        scrub: 0.5,
        trigger: ".hero-wrapper",
        start: "top",
        end: "bottom 1000px",
      },
      onUpdate: render,
    })

    images[0].onload = render

    function render() {
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.drawImage(
        images[purifier.frame],
        0,
        0,
        canvas.width,
        canvas.height
      )
    }
  }, [])

  React.useEffect(() => {
    const checkItemPosition = (e) => {
      const hero = document.querySelector('#lp-hero')

      setTransitElements((prevObj) => {
        return {
          ...prevObj,
          hero: Math.abs(hero.getBoundingClientRect().top) > hero.getBoundingClientRect().height - window.innerHeight / 2
        }
      })

      const features = document.querySelector('#lp-features')

      setTransitElements((prevObj) => {
        return {
          ...prevObj,
          features: features.getBoundingClientRect().top < window.innerHeight / 2
        }
      })
    }
    window.addEventListener('scroll', checkItemPosition )
    return () => window.removeEventListener('scroll', checkItemPosition)
  }, [])

  return (
    <Layout>
      {
        process.env.ENABLE_PRELOADER === `true` &&
        <div
            key={`loader`}
            id="___loader"
            style={{
              alignItems: "center",
              backgroundColor: "#000",
              display: "flex",
              justifyContent: "center",
              position: "fixed",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: 100000,
              flexDirection: `column`
            }}
        >
          <img 
          src={LoaderSVG} 
          alt="Loading spinner" 
          width="350" 
          />
          <div
            style={{ width: `80px`}}
            dangerouslySetInnerHTML={{ __html: preloaderSvg }}/>
        </div>
      }
      <section id="lp-hero"
        style={{
          backgroundImage: `url("${heroBg}")`
        }}
      className={`lp-dark-section lp-model-animation lp-border-transition lp-btm-right-radius-transition ${ transitElements.hero ? 'active' : '' }`}>
        <div className="hero-wrapper">
          <div className="hero-container">
            <canvas
              id="the-filter"
              ref={canvasRef}
              className={modelAnimEnd?.fixed ? "lp-fixed" : "lp-float"}
            ></canvas>
            <div className="lp-hero-msgs">
            {data.content.hero.messages.map((message, messageIdx) => {
              return (
                <div key={messageIdx} className="h1 lp-text-primary">
                  {message}
                </div>
              )
            })}
            </div>
            {/* TODO: don't forget the &nbsp; that I put as last of messages */}
          </div>
          <div className="lp-limpar-msg">
            <img src={limparImageMapper["logo-svg-dark-mode"]} />
          </div>
        </div>
      </section>

      <section id="lp-home-products" className="container lp-categories-width">
        <div className="row justify-content-center text-center">
          <div className="col-12 col-md-6">
            <h2>{data.content.products.title}</h2>
            <p>{data.content.products.text}</p>
            <div>
              <img
                className="lp-certification-images img-fluid"
                src={limparImageMapper["certifications"]}
              />
            </div>
          </div>
        </div>
        <CategoriesGrid maxItems="4" linkToAll="categories"/>
      </section>

      <section id="lp-features"
        style={{
          backgroundImage: `url("${darkBg}")`
        }}
        className={`lp-dark-section lp-border-transition lp-top-left-radius-transition ${ transitElements.features ? 'active' : '' }`}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-md-4">
              <h2>{data.content.features.title}</h2>
              <p>{data.content.features.text}</p>
            </div>
          </div>
          <div className="row lp-features-row">
            {/* <p>{ data.content.features.featuresList[0].description }</p> */}
            {data.content.features.featuresList
              .slice(0, 3)
              .map((feature, featureIdx) => {
                return (
                  <div key={featureIdx} className="col-6 col-md-4">
                    <div className="lp-feature">
                      <img
                        src={limparImageMapper[feature.image]}
                        alt="Limpar Model A9"
                      />
                      <div>{feature.description}</div>
                    </div>
                  </div>
                )
              })}
            <div className="d-none d-md-block col-md-12">
              <img
                className="lp-features-logo"
                src={limparImageMapper["logo-svg-dark-mode"]}
              />
            </div>
            {data.content.features.featuresList
              .slice(3, 6)
              .map((feature, featureIdx) => {
                return (
                  <div key={featureIdx} className="col-6 col-md-4">
                    <div className="lp-feature">
                      <img
                        src={limparImageMapper[feature.image]}
                        alt="Limpar Model A9"
                      />
                      <div>{feature.description}</div>
                    </div>
                  </div>
                )
              })}
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-md-4">
              <h2>{data.content.faq.title}</h2>
              <p>{data.content.faq.text}</p>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-md-8">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {data.content.faq.faqList.map((faq, faqIdx) => {
                  return (
                    <div key={faqIdx} className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`flush-heading${faqIdx}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${faqIdx}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${faqIdx}`}
                        >
                          {faq.question}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${faqIdx}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`flush-heading${faqIdx}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{faq.answer}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title={`Αρχική`} />

export default IndexPage
